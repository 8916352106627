import React from "react";
import { AxiosResponse } from "axios";
import { Message } from "semantic-ui-react";

interface IProps {
  error: AxiosResponse;
  text: string;
}

const ErrorMessage: React.FC<IProps> = ({ error, text }) => {
  return (
    <Message error>
      <Message.Header>{error.statusText}</Message.Header>
      {error.data && Object.keys(error.data.errors).length > 0 && (
        <Message.List>
          {typeof error.data.errors === "object" ? (
            Object.values(error.data.errors)
              .flat()
              .map((err, i) => <Message.Item key={i}>{err}</Message.Item>)
          ) : (
            <Message.Item>{error.data.errors}</Message.Item>
          )}
        </Message.List>
      )}
      {text && <Message.Content content={text} />}
    </Message>
  );
};

export default ErrorMessage;
